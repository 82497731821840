<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div uk-margin>
        <div uk-flex>
          <h5 class="uk-text-bold">
            Data Kandang
          </h5>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Kode Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.cage_code"
                  v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                  class="uk-input"
                  type="text"
                  name="kode_kandang"
                  placeholder="Masukan kode kandang..."
                  :class="{'uk-form-danger': errors.has('kode_kandang')}"
                >
                <div
                  v-show="errors.has('kode_kandang')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('kode_kandang') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Nama Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="uk-form-controls">
                  <input
                    v-model="formCage.name"
                    v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                    class="uk-input"
                    type="text"
                    name="nama_kandang"
                    placeholder="Masukan nama kandang..."
                    :class="{'uk-form-danger': errors.has('nama_kandang')}"
                  >
                  <div
                    v-show="errors.has('nama_kandang')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('nama_kandang') }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Nama Peternakan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="farm" 
                  v-model="farmChoose" 
                  v-validate="'required'"
                  label="name" 
                  name="peternakan" 
                  track-by="farm_code" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataFarms" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectFarm"
                  @remove="onRemoveFarm"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('peternakan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('peternakan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Status
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="status-active">
                  Aktif
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Anak Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="cage_staff" 
                  v-model="cageStaffChoose" 
                  label="name"
                  name="anak_kandang" 
                  track-by="code" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataCageStaffs" 
                  :searchable="true" 
                  :loading="isLoading"
                  :disabled="isDisabledSelect"
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('anak_kandang')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('anak_kandang') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Pengawas Lapangan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="field_supervisor" 
                  v-model="fieldSupervisorChoose" 
                  v-validate="'required'"
                  label="name" 
                  name="pengawas_lapangan" 
                  track-by="code" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataFieldSupervisors" 
                  :searchable="true"
                  :loading="isLoading"
                  :disabled="isDisabledSelect" 
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('pengawas_lapangan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('pengawas_lapangan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Cabang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="branch" 
                  v-model="branchChoose" 
                  v-validate="'required'"
                  label="name" 
                  name="cabang" 
                  track-by="name" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataBranches" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('cabang')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('cabang') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Kota/Kabupaten
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="city" 
                  v-model="cityChoose" 
                  v-validate="'required'"
                  label="city" 
                  name="kota" 
                  track-by="id" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataCities" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('kota')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('kota') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Alamat
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <textarea
                  v-model="formCage.address"
                  :class="{'uk-textarea': true}"
                  rows="5"
                />
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Luas Lahan (m2)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.total_area"
                  v-validate="'required|numeric'"
                  class="uk-input"
                  type="text"
                  name="luas_lahan"
                  placeholder="Masukan luas lahan..."
                  :class="{'uk-form-danger': errors.has('luas_lahan')}"
                >
                <div
                  v-show="errors.has('luas_lahan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('luas_lahan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Tipe Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="cage_category" 
                  v-model="cageCategoryChoose" 
                  v-validate="'required'"
                  label="name" 
                  name="tipe_kandang" 
                  track-by="id" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataCageCategorys" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectCageCategory"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('tipe_kandang')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('tipe_kandang') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Daya Tampung (ekor)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.chick_capacity"
                  class="uk-input"
                  type="text"
                  name="daya_tampung"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Tingkat
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.floor"
                  class="uk-input"
                  type="text"
                  name="floor"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Panjang (m)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.height"
                  class="uk-input"
                  type="text"
                  name="width"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Lebar (m)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.width"
                  class="uk-input"
                  type="text"
                  name="wide"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Kipas
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.fan"
                  class="uk-input"
                  type="text"
                  name="fan"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Pemanas
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.heater"
                  class="uk-input"
                  type="text"
                  name="heating"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Pajak Penghasilan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect
                  id="income_tax" 
                  v-model="tax_choose" 
                  v-validate="'required'"
                  label="name" 
                  name="income_tax" 
                  track-by="name" 
                  placeholder="Pilih pajak penghasilan..." 
                  open-direction="bottom" 
                  :options="tax_list" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('income_tax')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('income_tax') }}
                </div>
              </div>
            </div>
            <div
              v-if="show_npwp"
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                NPWP
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formCage.npwp"
                  v-mask="'##.###.###.#-###.###'"
                  v-validate="{ required: show_npwp }"
                  :maxlength="20"
                  class="uk-input"
                  type="text"
                  name="npwp_field"
                  placeholder="Masukan Nomor NPWP"
                  :class="{'uk-form-danger': errors.has('npwp_field')}"
                >
                <div
                  v-show="errors.has('npwp_field') || customError != ''"
                  class="uk-text-small uk-text-danger"
                >
                  {{ customError == '' ? errors.first('npwp_field') : customError }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
                Akuisisi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="acquisition_id" 
                  v-model="acquisitionIdChoose" 
                  label="acquisition_name" 
                  name="acquisition_id" 
                  track-by="acquisition_name" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :custom-label="customLabel"
                  :options="dataAcquisition" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                  :disabled="!cageCategoryChoose"
                  @search-change="handleDataAcquisition"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('acquisition_id')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('acquisition_id') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TODO -->
      <!-- <hr>
      <div uk-margin>
        <div uk-flex>
          <h5 class="uk-text-bold">Data Siklus</h5>
        </div>
        <div class="uk-grid">
          <div class="uk-width-1-2">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Populasi (ekor)</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="formCage.population"
                  type="text"
                  name="populasi"
                  v-validate="'required'"
                  placeholder="Masukan populasi..."
                  :class="{'uk-form-danger': errors.has('populasi')}"
                >
                <div class="uk-text-small uk-text-danger" v-show="errors.has('populasi')">{{ errors.first('populasi') }}</div>
              </div>
            </div>
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-2-5">Mulai Persiapan</div>
              <div class="uk-width-3-5">
                <select
                    class="uk-select"
                    v-model="formCage.start_prepare"
                    name="mulai_persiapan"
                    v-validate="'required'"
                    :class="{'uk-form-danger': errors.has('mulai_persiapan')}"
                  >
                    <option value=""  disabled>Pilih...</option>
                    <option v-for="role in roles.data" :key="role.id" :value="role.id">{{role.name}}</option>
                  </select>
                <div class="uk-text-small uk-text-danger" v-show="errors.has('mulai_persiapan')">{{ errors.first('mulai_persiapan') }}</div>
              </div>
            </div>
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-2-5">Chick In</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  type="text"
                  name="chick_in"
                  placeholder="-"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="uk-width-1-2">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-2-5">Mulai Panen</div>
              <div class="uk-width-3-5">
                <select
                    class="uk-select"
                    v-model="formCage.start_harvest"
                    name="mulai_panen"
                    v-validate="'required'"
                    :class="{'uk-form-danger': errors.has('mulai_panen')}"
                  >
                    <option value=""  disabled>Pilih...</option>
                    <option v-for="role in roles.data" :key="role.id" :value="role.id">{{role.name}}</option>
                  </select>
                <div class="uk-text-small uk-text-danger" v-show="errors.has('mulai_panen')">{{ errors.first('mulai_panen') }}</div>
              </div>
            </div>
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-2-5">Chick Out</div>
              <div class="uk-width-3-5">
                <select
                    class="uk-select"
                    v-model="formCage.chick_out"
                    name="chick_out"
                    v-validate="'required'"
                    :class="{'uk-form-danger': errors.has('chick_out')}"
                  >
                    <option value=""  disabled>Pilih...</option>
                    <option v-for="role in roles.data" :key="role.id" :value="role.id">{{role.name}}</option>
                  </select>
                <div class="uk-text-small uk-text-danger" v-show="errors.has('chick_out')">{{ errors.first('chick_out') }}</div>
              </div>
            </div>
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-2-5">Sanitasi</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  type="text"
                  name="sanitasi"
                  placeholder="-"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END TODO -->
      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-danger"
          type="button"
          @click="goToBack"
        >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateString } from '@/utils/formater'


export default {
  data() {
    return {
      customError: '',
      images: PREFIX_IMAGE,
      isLoading: true,
      formCage: {
        npwp: null,
        name: '',
        cage_code:'',
        city_id: '',
        cage_staff: '',
        field_supervisor: '',
        farm_id: '',
        total_area: '',
        height: '',
        width: '',
        cage_category_id: '',
        floor: '',
        heater: '',
        fan: '',
        chick_capacity: '',
        branch_id: '',
        address: '',
        acquisition_id: ''
      },
      farmChoose: '',
      cageStaffChoose: '',
      fieldSupervisorChoose: '',
      cageCategoryChoose: '',
      branchChoose: '',
      cityChoose: '',
      acquisitionIdChoose:{},
      dataFarms: [],
      dataCageStaffs: [],
      dataFieldSupervisors: [],
      dataCageCategorys: [],
      dataBranches: [],
      dataCities: [],
      dataAcquisition: [],
      isDisabledSelect: true,
      debounce: null,
      tax_choose: '',
      tax_list: [
        {
          name: 'Pribadi (PPh 21) dengan NPWP',
          show: true,
          value: "2.5"
        },
        {
          name: 'Pribadi (PPh 21) tanpa NPWP',
          show: false,
          value: '3'
        },
        {
          name: 'Badan (PPh 23) dengan NPWP',
          show: true,
          value: '2'
        },
        {
          name: 'Badan (PPh 23) tanpa NPWP',
          show: false,
          value: '4'
        }
      ],
      show_npwp: false
    }
  },
  computed: {
    customLabel() {
      return (option) => {
        if (option && option.acquisition_name) {
          const truncatedText = option.acquisition_name.length > 20 ? option.acquisition_name.slice(0, 10) + "..." : option.acquisition_name
          return `${truncatedText}`
        }
        return "Pilih..."
      }
    },
    ...mapGetters({
      farms: 'farm/farms',
      cageCategorys: 'cageCategory/cageCategorys',
      userCageStaffs: 'user/userCageStaffs',
      userFieldSupervisors: 'user/userFieldSupervisors',
      branches: 'branch/branches',
      cities: 'city/cities',
      dataListAcquisition: 'acquisition/dataListForCage'
    })
  },
  watch: {
    tax_choose() {
      this.show_npwp = this.tax_choose ? this.tax_choose.show : false
    },
    async 'cageCategoryChoose'(){
      this.formCage.acquisition_id=''
      this.handleDataAcquisition()
      return this.cageCategoryChoose
    },
    acquisitionIdChoose(){
      this.formCage.acquisition_id = this.acquisitionIdChoose
    },
    async 'formCage.npwp'(){
      if(this.formCage.npwp.length < 20){
        return this.customError = 'The tax_id_number field must be at least 15 characters'
      } else {
        return this.customError = ''
      }
    },
    async 'formCage.join_date'() {
      // SET FORMAT DATE
      this.formCage.join_date = this.dateFormatFilter(this.formCage.join_date)
    }
  },
  async mounted() {
    await Promise.all([
      this.handleFindFieldSupervisor(),
      this.getFarm({status:true, is_all: true}),
      this.getCageCategory({status:true, is_all: true}),
      this.getBranch({status:true, is_all: true}),
      this.getCity({is_all: true})
    ])

    this.dataFarms = this.farms ? this.farms : []
    this.dataCageCategorys = this.cageCategorys ? this.cageCategorys : []
    this.dataBranches = this.branches ? this.branches : []
    this.dataCities = this.cities ? this.cities : []
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      getFarm: 'farm/getFarm',
      getCageCategory: 'cageCategory/getCageCategory',
      getUserCageStaff: 'user/getUserCageStaff',
      getUserFieldSupervisor: 'user/getUserFieldSupervisor',
      getBranch: 'branch/getBranch',
      getCity: 'city/getCity',
      getDataList: 'acquisition/getDataListForCage'
    }),
    ...mapMutations({
      setModalAdd: 'cage/SET_MODAL_ADD'
    }),
    handleDataAcquisition(value) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        await this.getDataList({
          cage_category_id: this.cageCategoryChoose.id,
          acquisition_name: typeof value == 'object' ? '' : value
        })
        this.dataAcquisition = this.dataListAcquisition || []
      }, 600)
    },
    onSelectFarm(val) {
      this.cageStaffChoose = ''
      this.handleFindCageStaff(val.id)
      this.isDisabledSelect = false
    },
    onRemoveFarm() {
      this.cageStaffChoose = ''
      this.isDisabledSelect = true
    },
    onSelectCageCategory(val) {
      this.formCage.chick_capacity = val ? String(val.capacity) : ''
      this.formCage.floor = val ? String(val.floor) : ''
      this.formCage.height = val ? String(val.height) : ''
      this.formCage.width = val ? String(val.width) : ''
      this.formCage.fan = val ? String(val.fan) : ''
      this.formCage.heater = val ? String(val.heater) : ''
    },
    async handleFindCageStaff (farmId) {
      await this.getUserCageStaff({role:'Anak Kandang', status:true, farm_id:farmId, is_all: true})
      this.dataCageStaffs = this.userCageStaffs ? this.userCageStaffs : []
    },
    async handleFindFieldSupervisor () {
      await this.getUserFieldSupervisor({role:'PPL', status:true, is_all: true})
      this.dataFieldSupervisors = this.userFieldSupervisors ? this.userFieldSupervisors : []
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.formCage.farm_id = this.farmChoose ? this.farmChoose.id : ''
      this.formCage.cage_staff = this.cageStaffChoose ? this.cageStaffChoose.id : ''
      this.formCage.field_supervisor = this.fieldSupervisorChoose ? this.fieldSupervisorChoose.id : ''
      this.formCage.cage_category_id = this.cageCategoryChoose ? this.cageCategoryChoose.id : ''
      this.formCage.branch_id = this.branchChoose ? this.branchChoose.id : ''
      this.formCage.city_id = this.cityChoose ? this.cityChoose.id : ''
      this.formCage.acquisition_id = this.formCage.acquisition_id ? this.formCage.acquisition_id.id : ''
      this.formCage.income_tax = this.tax_choose.value
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setModalAdd(this.formCage)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>

<style scooped>
.status-active {
  border-radius: 8px;
  background: rgba(20, 182, 20, 0.16);
  border: 1px solid rgba(20, 182, 20, 0.2);
  color: #14b614;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
</style>
